import { render, staticRenderFns } from "./CitizenPasport.vue?vue&type=template&id=4f89220f&scoped=true&"
import script from "./CitizenPasport.vue?vue&type=script&lang=ts&"
export * from "./CitizenPasport.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f89220f",
  null
  
)

export default component.exports